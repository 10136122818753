import React from 'react';
import {HomeHeader} from "../components/HomeHeader";
import {Footer} from "../components/Footer";

const Home = () => {
    return (
        <div>
            <HomeHeader/>
            <Footer/>
        </div>
    );
};

export default Home;
